<template>
  <div id="TimeLine" class="px-8 white--text" style="background-color: #000000">
    <v-row>
      <v-col cols="12">
        <h2 class="text-center mt-4">Experiencia Laboral</h2>
        <p class="text-center my-4">
          Conoce mi experiencia e historias profesionales más importantes en mi
          vida laboral.
        </p>
      </v-col>
    </v-row>
    <v-divider />
    <v-row>
      <v-col cols="12">
        <v-timeline :dense="$vuetify.breakpoint.smAndDown" dark>
          <v-timeline-item
            v-for="(item, i) in items"
            :key="i"
            :color="item.color"
            :icon="item.icon"
            fill-dot
          >
            <template v-slot:opposite>
              <span
                :class="`headline font-weight-bold ${item.color}--text`"
                v-text="item.year"
              ></span>
            </template>
            <v-card :color="item.color">
              <v-card-title class="title text-capitalize">
                {{ item.title }}
              </v-card-title>
              <v-card-subtitle>
                {{ item.year }}
              </v-card-subtitle>
              <v-card-text class="white text--primary">
                <div class="py-4">
                  <v-img
                    v-if="item.src"
                    :src="item.src"
                    height="15vh"
                    contain
                  ></v-img>
                </div>
                <p>
                  {{ item.text }}
                </p>
                <div v-if="item.tecnologiesRight || item.tecnologiesLeft">
                  <h3>Tecnologías Aplicadas</h3>
                  <v-row>
                    <v-col md="6" cols="12" class="py-0">
                      <v-list dense light class="pb-0">
                        <v-list-item
                          v-for="(item, index) in item.tecnologiesRight"
                          :key="index"
                        >
                          <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>{{
                            item.title
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col md="6" cols="12" class="py-0">
                      <v-list
                        dense
                        light
                        :class="$vuetify.breakpoint.smAndDown ? pt - 0 : ''"
                      >
                        <v-list-item
                          v-for="(item, index) in item.tecnologiesLeft"
                          :key="index"
                        >
                          <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>{{
                            item.title
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </div>
                <div class="text-center">
                  <v-btn
                    v-if="item.url"
                    :color="item.color"
                    class="mx-0 text-capitalize"
                    @click="goToPage(item.url)"
                  >
                    Ir al sitio
                    <v-icon right>mdi-play</v-icon>
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "TimeLine",
  methods: {
    goToPage: function(url) {
      var a = document.createElement("a");
      a.target = "_blank";
      a.href = url;
      a.click();
    }
  },
  data: function() {
    return {
      items: [
        {
          year: "enero 2021",
          color: "#D1212C",
          icon: "mdi-briefcase",
          title: "Seguros el Potosí - Programador Analista",
          text: `Desarrollo full-stack para clientes internos para la administración de la compañía, así como, aplicaciones para clientes externos usando ASP.NET, Azure yVue.js.`,
          src: require("@/assets/images/timeLine/seguros.jpg"),
          url: "https://www.elpotosi.com.mx/",
          tecnologiesRight: [
            { icon: "mdi-jquery", title: "jQuery" },
            { icon: "mdi-dot-net", title: ".NET" },
            { icon: "mdi-vuejs", title: "Vue.js" },
            { icon: "mdi-microsoft-azure", title: "Microsoft Azure" }
          ]
        },
        {
          year: "agosto 2020",
          color: "#42B983",
          icon: "mdi-briefcase",
          title: "Desarrollador Freelance",
          text: `Desarrollo full-stack aplicación web para el manejo SASISOPA.

            Usando el framework Vue.js para el lado del cliente, para el lado del servidor se utilizó Node.js con el framework Express.
            También se hizo uso de Docker para el entorno de pruebas y despliegue en servidor.
            `,
          tecnologiesRight: [
            { icon: "mdi-vuejs", title: "Vue.js" },
            { icon: "mdi-firebase", title: "Firebase" },
            { icon: "mdi-vuetify", title: "Vuetify" }
          ],
          tecnologiesLeft: [
            { icon: "mdi-docker", title: "Docker" },
            { icon: "mdi-nodejs", title: "Node.js" },
            { icon: "mdi-language-typescript", title: "Typescript" }
          ]
        },
        {
          year: "agosto 2020",
          color: "#07579D",
          icon: "mdi-school",
          title: "UASLP - Concluyo Mis Estudios de Ingeniería Informática",
          text: `Luego de 5 años de carrera universitaria, termino mis estudios de Ingeniero en Informática
          con un amplio interés en tecnologías web.`,
          src: require("@/assets/images/timeLine/uaslp-logo.png"),
          url: "http://www.uaslp.mx/"
        },
        {
          year: "octubre 2019 - octubre 2020",
          color: "#210D32",
          icon: "mdi-briefcase",
          title: "Conviertes - Desarrollador Independiente",
          text: `Desarrollo de landing-page así como de aplicaciones web interactivas mediante el framework Vue.js, 
          creación de API REST mediante Node.js. Manejo y aplicación de conocimientos de firebase.
          Ayude a la implementación de metodologías ágiles y de nuevas tecnologías, ayudando así a reducir 
          tiempos de desarrollo.`,
          tecnologiesRight: [
            { icon: "mdi-language-php", title: "PHP" },
            { icon: "mdi-wordpress", title: "Wordpress" },
            { icon: "mdi-jquery", title: "jQuery" },
            { icon: "mdi-nodejs", title: "NodeJs" }
          ],
          tecnologiesLeft: [
            { icon: "mdi-vuejs", title: "Vue.js" },
            { icon: "mdi-angular", title: "Angular" },
            { icon: "mdi-firebase", title: "Firebase" }
          ],
          src: require("@/assets/images/logo_conviertes.png"),
          url: "https://conviertes.com/"
        },
        {
          year: "febrero 2019 - agosto 2019",
          color: "#C52E3F",
          icon: "mdi-desktop-classic",
          title: "IMEX - Prácticas Profesionales",
          text: `Desarrollo Full-Stack para software de control y administración de personal en su plataforma OLIN MIXTLI. 
          Impulse la aplicación de nuevas tecnologías de desarrollo como lo son Vue.js, Angular y Node.js respectivamente.`,
          tecnologiesRight: [
            { icon: "mdi-language-php", title: "PHP" },
            { icon: "mdi-bootstrap", title: "Bootstrap" },
            { icon: "mdi-jquery", title: "jQuery" },
            { icon: "mdi-language-csharp", title: "C#" }
          ],
          tecnologiesLeft: [
            { icon: "mdi-language-html5", title: "HTML" },
            { icon: "mdi-language-javascript", title: "Javascript" },
            { icon: "mdi-language-css3", title: "CSS" },
            { icon: "mdi-database", title: "MySQL" }
          ],
          src: require("@/assets/images/timeLine/imex.png"),
          url: "http://www.imexbioware.com.mx/"
        },
        {
          year: "agosto 2015",
          color: "#07579D",
          icon: "mdi-chair-school",
          title: "UASLP - Comienzo mis Estudios en Ingeniería Informática",
          text: `Impartida por la Facultad de Ingeniería por la Universidad Autónoma de San Luis Potosí, e inspirado
          por mis experiencias previas en concursos de programación y robótica, inicio mis estudios en el área que me
          ha apasionado desde el momento que tuve mi primera computadora.`,
          src: require("@/assets/images/timeLine/uaslp-logo.png"),
          url: "http://www.uaslp.mx/"
        }
      ]
    };
  }
};
</script>
